import React, { useEffect, useState } from "react";
import underline from "../assets/underline.png";
// import reviews from "../components/Reviews/Reviewpage/ReviewsData";
import { ReviewPageCard } from "../components/Reviews/Reviewpage/ReviewPageCard";
import { Loader } from "@googlemaps/js-api-loader";
export const ReviewsPage = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyDFsaRgxc2zJ7aAirYrcIr59rfgLHRdgGs",
      libraries: ["places"],
    });

    loader.load().then(() => {
      const placeService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      placeService.getDetails(
        {
          placeId: "ChIJE_CuEgD3UocRG42_6NkMjPI", // Replace with your business's Place ID
        },
        (place, status) => {
          if (status === "OK" && place.reviews) {
            setReviews(place.reviews);
          }
        }
      );
    });
  }, []);
  return (
    <div>
      <div className="relative w-fit my-4 mx-auto">
        <h1 className="title text-4xl text-center py-4">Our Reviews</h1>
        <img
          className="w-full h-20 absolute -bottom-6 left-0"
          src={underline}
          alt=""
        />
      </div>
      <div className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 px-4 py-4">
        {reviews.map((review) => (
          <ReviewPageCard data={review} />
        ))}
      </div>
      <a
        className="bg-orange-500 px-5 py-3 rounded-l-full border-2 border-white shadow-xl text-white fixed  right-0 bottom-10 hover:bg-orange-600 z-[9000]"
        href="https://g.page/r/CRuNv-jZDIzyEBM/review"
        target="_blank"
        rel="noreferrer"
      >
        Leave a review for us
      </a>
      <a
        className="bg-white hover:text-white px-5 py-3 rounded-l-full border-2 border-orange-500 shadow-xl text-black fixed  right-0 bottom-24 hover:bg-orange-600 z-[9000]"
        href="https://www.google.com/search?q=fresh+sushi+boba+tea&sca_esv=247a3cc0ba513c42&authuser=2&sxsrf=ADLYWIK5Qyvpb1VYKCdF6zAQgWggvzvveQ%3A1728498357939&source=hp&ei=tcoGZ5CqN5G-kPIPrYC6yAU&iflsig=AL9hbdgAAAAAZwbYxf8VqI46Jf_wd2N8oKecwuWhiFGm&oq=fre&gs_lp=Egdnd3Mtd2l6IgNmcmUqAggBMgQQIxgnMgQQIxgnMgQQIxgnMgsQABiABBiRAhiKBTILEAAYgAQYkQIYigUyFhAuGIAEGLEDGNEDGEMYgwEYxwEYigUyChAAGIAEGEMYigUyEBAAGIAEGLEDGEMYgwEYigUyChAAGIAEGEMYigUyDRAuGIAEGEMY1AIYigVIzAxQAFinA3AAeACQAQCYAVWgAesBqgEBM7gBAcgBAPgBAZgCA6AC9wHCAhEQLhiABBiRAhjRAxjHARiKBcICERAuGIAEGLEDGNEDGIMBGMcBwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICDhAAGIAEGLEDGIMBGIoFwgIQEC4YgAQY0QMYQxjHARiKBcICChAuGIAEGEMYigWYAwCSBwEzoAfOKQ&sclient=gws-wiz#mpd=~10499319506908873182/customers/reviews"
        target="_blank"
        rel="noreferrer"
      >
        View more reviews
      </a>
    </div>
  );
};
