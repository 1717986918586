import React from "react";
import underline from "../assets/underline.png";
export const AboutUs = () => {
  const styles = {
    backgroundImage: `url('https://images.pexels.com/photos/3298179/pexels-photo-3298179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "400px", // Adjust height as needed
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // Add this to make the overlay absolute positioned
  };
  return (
    <div>
      <div
        className="relative h-400px bg-cover bg-center flex justify-center items-center"
        style={styles}
      >
        <div className="absolute inset-0 bg-black/40 flex justify-center items-center">
          <div className="relative w-fit mx-auto">
            <h2 className="poppins-regular-italic text-white text-center text-4xl">
              About Us
            </h2>
            {/* <h1 className='text-4xl text-center py-4'>Menu</h1> */}
            <img
              className="w-full h-20 absolute -bottom-10 left-0"
              src={underline}
              alt=""
            />
          </div>
        </div>
      </div>
      <div id="about-us" class="max-w-5xl mx-auto px-4 py-8 flex">
        {/* <div className='w-[600px] top-32 left-10'>
      <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/3814446/pexels-photo-3814446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" />
      </div> */}
        <div>
          <h2 class="text-3xl font-bold mb-4">
            Welcome to Fresh Sushi & Boba Tea Co
          </h2>
          <p class="mb-4">
            where culinary mastery meets heartfelt hospitality.
          </p>

          <p class="mb-4">
            Fresh Sushi & Boba Tea Co INCot is the culmination of a remarkable
            journey that began over two decades ago under the guidance of our
            founder, Sanuk. With a vision to share the authentic flavors of
            Japan and the vibrant culture of boba tea, Sanuk embarked on a path
            that would ultimately lead to the creation of seven beloved
            locations across Utah.
          </p>

          <h3 class="text-2xl font-bold mt-8 mb-4">Sanuk's Journey</h3>
          <p class="mb-4">
            With over 20 years of experience in the sushi industry, Sanuk honed
            his craft through dedication, perseverance, and a relentless pursuit
            of excellence. Starting as an apprentice under the guidance of a
            seasoned sushi chef, he immersed himself in the artistry of
            sushi-making, mastering each technique through hard work and
            determination.
          </p>

          <p class="mb-4">
            Driven by his passion for culinary innovation and a deep-rooted
            commitment to quality, Sanuk ventured on his own path, establishing
            Fresh Sushi & Boba Tea Co INCot as a beacon of authenticity and
            creativity in the sushi and boba community.
          </p>

          <h3 class="text-2xl font-bold mt-8 mb-4">Our Commitment</h3>
          <p class="mb-4">
            At Fresh Sushi & Boba Tea Co INCot, we remain steadfast in our
            commitment to delivering exceptional dining experiences rooted in
            tradition, flavor, and hospitality. Each of our seven locations
            embodies the spirit of Sanuk's journey – a testament to the belief
            that great food has the power to unite, inspire, and nourish the
            soul.
          </p>

          <h3 class="text-2xl font-bold mt-8 mb-4">Visit Us</h3>
          <p class="mb-4">
            Join us at Fresh Sushi & Boba Tea Co INCot and embark on a culinary
            adventure guided by passion, expertise, and a genuine love for what
            we do. Whether you're savoring our signature sushi rolls or
            indulging in a refreshing boba tea creation, we invite you to
            experience the magic of Fresh Sushi & Boba Tea Co INCot – where
            every bite tells a story, and every visit feels like coming home.
          </p>

          <p>
            Thank you for being a part of Sanuk's journey. We look forward to
            serving you at Fresh Sushi & Boba Tea Co.
          </p>
        </div>
      </div>
    </div>
  );
};
