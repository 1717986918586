import { FaArrowRight } from "react-icons/fa6";
import { Slider } from "./Slider";

function Reviews() {
  return (
    <div id="section-5" className="bg-gradient-to-r from-orange-50 to-orange-400  py-10">
      <div className="px-6 md:w-[80%] mx-auto max-w-7xl lg:flex lg:w-[95%] lg:space-x-10">
        <div>

        <h1 className="font-bold  text-3xl pt-10 lg:text-4xl text-center lg:w-[600px] text-gray-700 leading-7">
        Here are some testimonials from our satisfied customers:
        </h1>
        <div className="flex justify-center">
        <a href="/reviews" className="border bg-gray-100  rounded px-4 py-3 flex items-center text-gray-500 hover:border-none my-4 hover:bg-orange-500 hover:text-white">
          See More Reviews <FaArrowRight className="ml-2" />
        </a>
      </div>
        </div>
        <div className="relative my-10 lg:my-0 mx-auto bg-white md:w-[95%] lg:w-[60%] h-full rounded-xl overflow-hidden">
          <Slider />
        </div>
      </div>

    
    </div>
  );
}

export default Reviews;
