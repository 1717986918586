import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../App";
import notificationSound from "../assets/notification.mp3"; // Ensure you have a notification sound file
import { toast } from "react-toastify";
export const OwnerView = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [filteredInCompletedOrders, setFilteredInCompleteOrders] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [receivedMessages, setReceivedMessages] = useState([]);
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("logged");
  const url = localStorage.getItem("url");
  const [activeTab, setActiveTab] = useState("total"); // State to manage the active tab

  // Function to handle tab click and update active tab state
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const utahTime = (utcTimestamp) => {
    // Parse the UTC timestamp string into a Date object
    const time = new Date(utcTimestamp);

    // Adjust the time to Mountain Daylight Time (MDT) for Utah (UTC-6:00)
    time.setHours(time.getHours());

    // Format the adjusted time for display in the desired format
    const formattedUtahTime = time.toLocaleString("en-US", {
      timeZone: "America/Denver", // Specify the timezone (Mountain Time - Denver)
      hour12: true, // Use 12-hour format
      weekday: "long", // Display full weekday name
      year: "numeric", // Display full year
      month: "long", // Display full month name
      day: "numeric", // Display day of the month
      hour: "numeric", // Display hour (numeric)
      minute: "numeric", // Display minute (numeric)
      second: "numeric", // Display second (numeric)
      timeZoneName: "short", // Display abbreviated timezone name (MDT)
    });

    return formattedUtahTime;
  };

  useEffect(() => {
    if (!user) {
      navigate("/admin");
    }

    const fetchOrders = async () => {
      try {
        if (user) {
          const response = isAdmin
            ? await axios.get(`${url}api/get-completed-order`)
            : await axios.get(`${url}api/get-one-completed-order/${user}`);

          const orderData = response.data;
          setOrders(orderData);
          setFilteredOrders(orderData);
          // console.log(orderData)

          
          const filteredCompletedOrders = orderData.filter(
            (order) => order.completed
          );
          setCompletedOrders(filteredCompletedOrders);

          const filteredInCompleteOrders = orderData.filter(
            (order) => !order.completed
          );
          setFilteredInCompleteOrders(filteredInCompleteOrders);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        // Handle error if needed (e.g., show error message)
      }
    };

    
    // Determine if the user is an admin (based on role)
    const checkUserRole = async () => {
      try {
        const response = await axios.get(`${url}api/one-user/${user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { role } = response.data;
        if (role !== "admin") {
          navigate("/"); // Redirect if not admin
        }
        
        setIsAdmin(role === "admin");
      } catch (error) {
        console.error("Error fetching user role:", error);
        // Default to non-admin role if user role cannot be determined
        setIsAdmin(false);
      }
    };

    // Fetch user role and orders when the component mounts
    checkUserRole();
    fetchOrders();

    // Polling for new orders every 30 seconds
    const intervalId = setInterval(async () => {
      try {
        const response = isAdmin
          ? await axios.get(`${url}api/get-completed-order`)
          : await axios.get(`${url}api/get-one-completed-order/${user}`);
        const newOrderData = response.data;

        // Check if there's a new order by comparing with the current orders length
        if (newOrderData.length > orders.length) {
          // Play notification sound
          const audio = new Audio(notificationSound);
          audio.play();
          toast.success("You've got an order!")

          // Update the orders list
          setOrders(newOrderData);
          setFilteredOrders(newOrderData);
          setFilteredInCompleteOrders(newOrderData.filter((order) => !order.completed));
        }
      } catch (error) {
        console.error("Error during polling:", error);
      }
    }, 30000); // Poll every 30 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [
    isAdmin,
    user,
    url,
    completedOrders,
    filteredInCompletedOrders,
    filteredOrders,
    token,
  ]);

  const updateCompletedOrder = async (id) => {
    try {
      await axios.post(`${url}api/update-completed-order`, { id: id });
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  // Autosuggest render function
  const renderSuggestion = (suggestion) => (
    <div className="text-sm text-gray-800 p-2 cursor-pointer hover:bg-gray-300 rounded border-b">
      <div>{suggestion.name}</div>
      <div className="text-xs text-gray-600">${suggestion.price}</div>
      <div className="text-xs text-gray-500">{suggestion.current_user}</div>
    </div>
  );

  // Autosuggest inputProps
  const inputProps = {
    placeholder: "Search Item Name, Date, Customers...",
    value: inputValue,
    onChange: (event, { newValue }) => setInputValue(newValue),
    className:
      "block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500 placeholder-gray-400 focus:ring-1 focus:outline-none",
  };

  // Autosuggest getSuggestions function
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    return orders.filter(
      (order) =>
        order.name?.toLowerCase().includes(inputValue) ||
        order.current_user?.toLowerCase().includes(inputValue)
      // Add more conditions for other searchable fields (e.g., date, price)
    );
  };

  return (
    <div className="my-8 px-4">
      <h2 className="text-2xl font-bold mb-4">
        Orders ({filteredOrders.length})
      </h2>
      <div className="relative">
        <Autosuggest
          suggestions={getSuggestions(inputValue)}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <button
          className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white px-4 py-2 h-[53px] rounded-r-lg"
          onClick={() => setInputValue("")}
        >
          Clear
        </button>
      </div>
      <div className="flex items-center space-x-4 border w-fit my-4 rounded px-2">
        {/* Total Orders Tab */}
        <div
          className={`flex items-center space-x-4 cursor-pointer ${
            activeTab === "total" ? "text-red-500" : "text-gray-200"
          }`}
          onClick={() => handleTabClick("total")}
        >
          <p className="text-sm">Incomplete Orders:</p>
          <p className="text-3xl font-bold">
            {filteredInCompletedOrders.length}
          </p>
        </div>

        {/* Completed Orders Tab */}
        <div
          className={`flex items-center space-x-4 cursor-pointer border-l pl-2 ${
            activeTab === "completed" ? "text-red-500 " : "text-gray-200"
          }`}
          onClick={() => handleTabClick("completed")}
        >
          <p className="text-sm">Completed Orders:</p>
          <p className="text-3xl font-bold">{completedOrders.length}</p>
        </div>
      </div>
      {activeTab === "total" ? (
        <>
          {filteredOrders.length > 0 ? (
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredOrders
                .filter((item) => item.completed === false)
                .map((order) => (
                  <li
                    key={order._id}
                    className="border rounded-lg overflow-hidden shadow-md p-4"
                  >
                    {/* <img
                      src={order.imageUrl}
                      alt={order.name}
                      className="w-full h-48 object-cover"
                    /> */}
                    <div className="p-4">
                      <p className="text-lg font-semibold mb-2">{order.name}</p>
                      <p className="text-gray-600">${order.price}</p>
                      <p className="text-gray-600">{order.current_user}</p>
                      <p className="text-gray-600">
                        Quantity: {order.quantity}
                      </p>
                        <p className="text-gray-600">
                        Size: {order?.size || 'N/A'}
                      </p>
                        <p className="text-gray-600">
                        Base: {order?.base || 'N/A'}
                      </p>
                        <p className="text-gray-600">
                        Flavor: {order?.flavor || 'N/A'}
                      </p>
                      
                      <p className="text-gray-600">
                        Toppings: {order.toppings?.join(", ") || "None"}
                      </p>
                      <p className="text-gray-600">
                        Extra Toppings: {order.toppingsExtra?.join(", ") || "None"}
                      </p>
                      <p className="text-gray-600">
                        Meat: {order?.meat?.join(", ") || 'N/A'}
                      </p>
                      <p className="text-gray-600">
                        Carb: {order?.carb || 'N/A'}
                      </p>
                    </div>
                    <button
                      onClick={() => updateCompletedOrder(order._id)}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg mt-2"
                    >
                      Complete Order
                    </button>
                    {/* <p className="text-gray-600">Time: {order.createdAt}</p> */}
                    {/* <p className="text-gray-600">completed {order.completed}</p> */}
                    {/* <p>{formattedUtahTime}</p> */}
                    <p>Order time: {utahTime(order.createdAt)}</p>
                    <p>Table Number: {order.tableNumber}</p>
                  </li>
                ))}
            </ul>
          ) : (
            <p className="text-lg">
              No orders found{isAdmin ? "" : " for this user"}.
            </p>
          )}
        </>
      ) : (
        <>
          {filteredOrders.length > 0 ? (
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredOrders
                .filter((item) => item.completed === true)
                .map((order) => (
                  <li
                    key={order._id}
                    className="p-4 border rounded-lg overflow-hidden shadow-md"
                  >
                    <img
                      src={order.imageUrl}
                      alt={order.name}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4">
                      <p className="text-lg font-semibold mb-2">{order.name}</p>
                      <p className="text-gray-600">${order.price}</p>
                      <p className="text-gray-600">{order.current_user}</p>
                      <p className="text-gray-600">
                        Quantity: {order.quantity}
                      </p>

                    </div>
                    {/* <button onClick={() =>  updateCompletedOrder(order._id)} className="bg-red-500 text-white px-4 py-2 rounded-lg mt-2">Complete</button> */}
                    <p className="text-gray-600">Time: {order.createdAt}</p>
                    <p className="text-gray-600">completed {order.completed}</p>
                  </li>
                ))}
            </ul>
          ) : (
            <p className="text-lg">
              No orders found{isAdmin ? "" : " for this user"}.
            </p>
          )}
        </>
      )}
    </div>
  );
};
