import React from 'react';

export const Success = () => {
  return (
    <div className='relative w-full z-[8000]'>
      <h1 className='text-center text-xl absolute top-10 left-1/2 transform -translate-x-1/2 text-white'>
        We are preparing your order.
      </h1>
      <img className='w-full' src="https://cdn.dribbble.com/users/275118/screenshots/1391334/cserving1.gif" alt="" />
    </div>
  );
};
