import React, { useState, useContext } from "react";
import { AppProvider } from "../../App";
import { BiCart } from "react-icons/bi";
import { RiEyeLine } from "react-icons/ri";
import { toast } from "react-toastify";
import PriceSelector from "./PriceSelector"; // Import the PriceSelector component

export const TrendingCard = ({ item }) => {
  const { addItem, setCartChanged, cartChanged } = useContext(AppProvider);
  const [showPriceSelector, setShowPriceSelector] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);

  // Function to check if the current time is within allowed hours (11:00 AM - 7:30 PM)
  const isWithinOrderTime = () => {
    const currentTime = new Date();
    const startTime = new Date();
    startTime.setHours(11, 0, 0); // 11:00 AM

    const endTime = new Date();
    endTime.setHours(19, 30, 0); // 7:30 PM

    return currentTime >= startTime && currentTime <= endTime;
  };

  // Function to handle adding selected item to cart
  const handleAddToCart = (selectedItems) => {
    if (!isWithinOrderTime()) {
      toast.error("Orders are only allowed between 11:00 AM and 7:30 PM");
      return;
    }

    if (!localStorage.getItem("logged")) {
      toast.error("Please login!");
    } else {
      const selectedItemData = {
        id: item.id,
        name: item.name,
        price: selectedItems.price,
        size: selectedItems.size,
        base: selectedItems.base,
        flavors: selectedItems.flavors,
        toppings: selectedItems.toppings || [],
        toppingsExtra: selectedItems.toppingsExtra || [],
        meat: selectedItems.meat || "",
        spiceLevel: selectedItems.spiceLevel,
        carb: selectedItems.carb,
        drinks: selectedItems.drinks,
      };

      console.log("selected items", selectedItems);
      addItem(selectedItemData, localStorage.getItem("logged"));
      toast.success(`${item.name} is added to the cart`);
      setCartChanged(!cartChanged);
    }
  };

  return (
    <div className="gap-4">
      <div key={item.id} className="relative w-full group rounded-lg p-4">
        <img
          className="w-full h-80 lg:h-auto group-hover:scale-105 transition-all duration-300 ease-linear object-cover rounded-md mb-4"
          src={item.image}
          alt={item.name}
        />
        <div className="absolute bottom-[40px] md:bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:hidden group-hover:block transition-all duration-500 ease-linear">
          <div className="flex flex-row items-center space-x-3">
            {/* Eye Icon (View Details) */}
            <a
              href={`/ingredientPage/${item.id}`}
              className="p-2 text-white rounded-full bg-orange-500 hover:bg-orange-300 cursor-pointer"
            >
              <RiEyeLine className="text-3xl md:text-2xl" />
            </a>
            {/* Cart Icon (Add to Cart) */}
            <button
              onClick={() => {
                if (
                  item.prices?.length > 1 ||
                  item.meats ||
                  item.carb ||
                  item.category === "Slushies" ||
                  item.category === "Smoothies" ||
                  item.category === "Fruit Teas" ||
                  item.category === "Soft Drinks"
                ) {
                  setShowPriceSelector(true);
                } else {
                  // If there's only one price, add the item directly with no selection
                  handleAddToCart({
                    price: item.price,
                    size: null,
                    toppings: [],
                  });
                }
              }}
              className="rounded-full p-2 text-white bg-orange-500 hover:bg-orange-300 cursor-pointer"
            >
              <BiCart className="text-3xl md:text-2xl" />
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center md:pt-0">
          <p className="font-semibold text-xl">{item.name}</p>
          <p className="font-bold p-4 text-orange-500 rounded-full flex items-center justify-center">
            ${item.prices?.length > 1 ? "More than one price" : item.price}
          </p>
        </div>
      </div>
      {showPriceSelector && (
        <PriceSelector
          base={item.base}
          toppings={item.toppings}
          flavors={item.flavors}
          toppingsExtra={item.toppingsExtra}
          prices={item.prices}
          meats={item.meats}
          sizes={item.sizes}
          drinks={item.drinks}
          price={item.price}
          spiceLevels={item.spices}
          carb={item.carb}
          onSelect={(priceDetails) => {
            const {
              totalPrice,
              size,
              selectedToppings,
              selectedToppingsExtra,
              selectedMeats,
              selectedSpiceLevel,
              selectedCarb,
              selectedBase,
              selectedFlavor,
              selectedDrinks,
            } = priceDetails;
            setSelectedPrice(totalPrice);
            setShowPriceSelector(false);
            handleAddToCart({
              price: totalPrice || item.price,
              size,
              toppings: selectedToppings,
              toppingsExtra: selectedToppingsExtra,
              meat: selectedMeats,
              spiceLevel: selectedSpiceLevel,
              carb: selectedCarb,
              base: selectedBase,
              flavors: selectedFlavor,
              drinks: selectedDrinks,
            });
          }}
          onClose={() => setShowPriceSelector(false)}
        />
      )}
    </div>
  );
};
