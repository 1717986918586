import React from 'react'
import { Banner } from './Banner'
import { CarouselSection } from '../Carousel/CarouselSection'

export const MainBanner = () => {

  return (
    <div className="relative z-[5000] bg-cover bg-center h-[650px] lg:h-[670px] px-8 " style={{backgroundImage: "url('https://images.pexels.com/photos/359993/pexels-photo-359993.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"}}>
    <div className='absolute inset-0 bg-black/30'/>
    <div className='relative flex flex-col justify-center text-white max-w-7xl mx-auto h-full z-[400] md:pl-10'> {/* Increased z-index here */}
        <h3 className='text-2xl text-orange-600 lg:text-4xl'>#1 <span className='text-white'>Rated</span></h3>
        <h1 className='poppins-bold text-5xl py-2 lg:text-[80px] lg:py-4 lg:max-w-3xl'>Fresh Sushi & Boba Tea</h1>
        <a href='/menu' className='bg-orange-600 text-white px-4 py-3 w-fit lg:text-2xl rounded'>VIEW MENU</a>
    </div>
    <div className='absolute inset-x-0 bottom-0 flex justify-center'>
    <div className='relative -bottom-80 lg:-bottom-20 w-[90%] md:w-[700px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1500px]'>
        <Banner/>
    </div>
    </div>
{/* <CarouselSection/> */}
</div>


  )
}
