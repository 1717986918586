import React from 'react'

export const NewsLetter = () => {
  return (
    <div className='text-center py-4 lg:flex lg:text-left justify-between px-8 max-w-7xl mx-auto items-center'>
    <div className=''>
        <h1 className='text-xl py-1'>KNOW IT ALL FIRST!</h1>
        <p className='hidden lg:block font-[200] text-gray-500'>Never Miss Anything From Us By Signing Up To Our Newsletter.</p>
    </div>
    <hr className='hidden lg:block h-20 w-[1px] bg-gray-300'/>
    <div className=''>
        <input className='bg-white my-4 border px-4 py-3 mr-3 lg:w-80' type="text" placeholder='Enter your email'/>
        <button className="border bg-gray-100 border-gray-300 rounded px-4 py-3 text-gray-500 hover:border-none my-4 hover:bg-orange-500 hover:text-white">SUBSCRIBE</button>
    </div>
</div>
  )
}
