import React, { useContext } from "react";
import { Ingredient } from "../components/Menu/Ingredient";
import { useParams } from "react-router-dom";
import items from "../components/Trending/TrendingData";
import { AppProvider } from "../App";
import { toast } from "react-toastify";
export const IngredientPage = () => {
  const { id } = useParams();
  const {addItem} = useContext(AppProvider)
  return (
    <div className="max-w-7xl mx-auto">
      {items.map((item) => {
        if (item.id == id) {
          return (
            <div className="relative px-4 py-4 lg:flex items-center justify-center">
              <img className="w-[400px]" src={item.image} alt="" />
              <div>
                <div className="border rounded p-4">
                  <Ingredient data={item} />
                </div>
                <div className="absolute top-4 right-0 md:right-40 lg:left-0 bg-white p-4 rounded w-fit">
                  <p className="pt-4">{item.name}</p>
                  <p className="font-bold py-2">${item.price}</p>
                  <p className="flex space-x-1">{item.stars}</p>
                </div>
                {/* <button   onClick={() => {
                if (!localStorage.getItem("logged")) {
                  toast.error("please login!");
                  console.log('login')
                } else {
                  addItem(item, localStorage.getItem("logged"));
                  toast.success(`${item.name} is added`)
                }
              }} className="bg-orange-500 px-4 py-3 rounded-xl text-white m-4">Add to Cart</button> */}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
