import React, { useContext, useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { AppProvider } from "../../App";
import axios from "axios";
import Modal from "react-modal";
import { toast } from "react-toastify";

export const Cart = ({ setShowCart }) => {
  const [cartItems, setCartItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [tableNumber, setTableNumber] = useState("");

  const {
    increaseItem,
    decreaseItem,
    clearCart,
    setWebSocketMessage,
    webSocketMessage,
    deletItem,
    url,
  } = useContext(AppProvider);
  const loggedUser = localStorage.getItem("logged");

  // Fetch cart data
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(`${url}api/cart/${loggedUser}`);
        if (Array.isArray(response.data)) {
          setCartItems(response.data);
        } else {
          setCartItems([]);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
        setCartItems([]);
      }
    };

    fetchCartItems();
  }, [increaseItem, decreaseItem, deletItem]);

  // Calculate total price
  const totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  const taxAmount = parseFloat((totalPrice * 0.0485).toFixed(2));
  const totalWithTax = (totalPrice + taxAmount).toFixed(2);

  // Column mapping
  const columnMapping = {
    quantity: "Quantity",
    name: "Item",
    category: "Category",
    price: "Price",
    size: "Size",
    toppings: "Toppings",
    toppingsExtra: "ExtraToppings",
  };

  // Filter columns to display only those with data
  const columnsToDisplay = Object.keys(columnMapping).filter((key) =>
    cartItems.some((item) => item[key] && item[key].length !== 0)
  );

  const handleCheckout = async () => {
    const currentDate = new Date();
    const formattedTime = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    try {
      const response = await axios.post(`${url}create-checkout-session`, {
        userId: loggedUser,
        cartItems,
        formattedTime,
        tableNumber: orderType === "dine-in" ? tableNumber : "",
      });
      window.location = response.data.url;
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div className="text-black min-h-[440px] max-w-7xl mx-auto h-full overflow-y-scroll text-lg p-4 m-4 rounded-lg">
      {cartItems.length > 0 ? (
        <>
          <h1>Your Cart ({cartItems.length}) total</h1>
          <hr className="my-4 border-[#D3D3D3]" />

          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {columnsToDisplay.map((key) => (
                  <th key={key} className="py-2">
                    {columnMapping[key]}
                  </th>
                ))}
                <th className="py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item) => (
                <tr key={item._id} className="border-t">
                  {columnsToDisplay.map((key) => (
                    <td key={key} className="py-2 text-center">
                      {Array.isArray(item[key])
                        ? item[key].join(", ")
                        : item[key] || "N/A"}
                    </td>
                  ))}
                  <td className="py-2 text-center">
                    <button
                      onClick={() => decreaseItem(item._id)}
                      className="px-2"
                    >
                      -
                    </button>
                  
                    <button
                      onClick={() => increaseItem(item._id)}
                      className="px-2"
                    >
                      +
                    </button>
                    <FaRegTrashAlt
                      onClick={() => deletItem(item._id)}
                      className="cursor-pointer p-2 text-3xl rounded-md"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <hr className="my-4 border-[#D3D3D3]" />

          <div>
            <div className="flex items-center justify-between">
              <p>Cart items</p>
              <p>${totalPrice.toFixed(2)}</p>
            </div>
            <div className="flex items-center justify-between">
              <p>Tax (4.85%)</p>
              <p>${taxAmount}</p>
            </div>
            <div className="flex items-center justify-between">
              <p>Total to pay</p>
              <p>${totalWithTax}</p>
            </div>
            <button
              onClick={() => setShowModal(true)}
              className="bg-black text-white w-full rounded-lg py-2 mt-4"
            >
              Checkout
            </button>
          </div>
        </>
      ) : (
        <p className="text-center">
          Your cart is empty, please order! <br />{" "}
          <a className="underline text-orange-500 text-xl" href="/menu">
            Menu
          </a>
        </p>
      )}

      {/* Modal for Order Type */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Order Type"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Select Order Type</h2>
        <div className="flex flex-col space-y-4">
          <button
            onClick={() => setOrderType("dine-in")}
            className={`w-full p-2 border rounded ${
              orderType === "dine-in" ? "bg-orange-500 text-white" : "bg-white"
            }`}
          >
            Dine In
          </button>
          <button
            onClick={() => {
              setOrderType("take-out");
              setTableNumber("");
            }}
            className={`w-full p-2 border rounded ${
              orderType === "take-out" ? "bg-orange-500 text-white" : "bg-white"
            }`}
          >
            Take Out
          </button>
        </div>

        <div className="flex justify-between space-x-4 mt-4">
          <button
            onClick={() => handleCheckout()}
            className="bg-orange-500 text-white px-4 py-2 rounded"
          >
            Confirm
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};
