import React, { useContext, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { AppProvider } from '../App';

export const ResetPass = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const {url} = useContext(AppProvider)
const navigate = useNavigate()
  const handleResetRequest = async (e) => {
    e.preventDefault();
    try {
      // Send a POST request to your backend endpoint to initiate password reset
      const response = await axios.post(`${url}api/forgot-password`, { email });
      setMessage(response.data.message);
      setTimeout(() => {

        if(response.data.success){
          navigate(`/`)
        }
      }, 2000)
    } catch (error) {
      setMessage('user not found');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen text-black">
      <form onSubmit={handleResetRequest} className="w-96 m-4">
        <h1 className="font-bold text-4xl text-center text-black mb-8 w-full">Reset Your Password</h1>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-white">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='email'
            className="mt-1 text-black px-4 block w-full rounded-full border py-3 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-500 focus:ring-opacity-50"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-3 px-5 rounded-full hover:bg-orange-800 focus:outline-none focus:ring focus:ring-gray-500 focus:ring-opacity-50"
        >
          Request Reset Link
        </button>
        {message && <p className="mt-4 text-center text-gray-500">{message}</p>}
      </form>
    </div>
  );
};


