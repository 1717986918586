import React, { useState } from "react";
import { MainBanner } from "../components/Banner/MainBanner";
import { Trending } from "../components/Trending/Trending";
import { Offer } from "../components/Offer/Offer";
import { NewProduct } from "../components/NewProducts/NewProduct";
import { Sidebar } from "../components/SideBar/Sidebar";
import Reviews from "../components/Reviews/Reviews";

const Popup = ({ onClose }) => {
  return (
    <div className="z-[8000] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
        <h2 className="text-xl font-bold mb-4 text-orange-500">Notice</h2>
        <p>Our menu and prices etc. are not updated at the moment. We are working on it. Please be patient while we get these sorted out for you. Thanks!</p>
        <button
          onClick={onClose}
          className="mt-6 bg-orange-500 text-white px-4 py-2 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  return (
    <div className="">
      {/* {showPopup && <Popup onClose={() => setShowPopup(false)} />} */}
      <MainBanner />
      <Trending />
      <Offer />
      <NewProduct />
      <Reviews />
      <Sidebar />
    </div>
  );
};
