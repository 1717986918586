import React from "react";
import { CiHeart } from "react-icons/ci";

export const NewProductCard = ({ item }) => {
  return (
    <div className="w-full p-4 group flex rounded-lg drop-shadow-md text-center py-4">
      <img className="w-20 h-20 object-contain" src={item.image} alt="" />
      <div className="flex flex-col text-left space-x-2 justify-center">
        <div className="flex items-center space-x-1  pt-4">
          {item.stars.map((star, index) => (
            <span key={index}>{star}</span>
          ))}
        </div>
        <p className="font-[300]">{item.name}</p>
        <p className="font-bold text-xl">{item.price}</p>
      
      {/* <button className="bg-gray-200 px-4 py-2 w-full">
        {item.buttonText}
      </button> */}
      </div>
      <CiHeart className="absolute cursor-pointer bg-gray-200 p-2 block group-hover:block lg:hidden top-0 right-0 rounded-lg text-4xl" />
    </div>
  );
};
