import React from "react";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

export const Info = () => {
  const iconSize = "text-lg"; // Define the size for all icons
  const listItemClass = "flex items-center py-2";

  return (
    <div>
      <h1 className="font-semibold">STORE INFORMATION</h1>
      <ul className="text-gray-500">
        <li className={listItemClass}>
          <div className="inline-block">
            <FaMapMarkerAlt className={iconSize} />
          </div>
          <p className="inline-block ml-2">3391 Orchard Dr Bountiful UT 84010</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <FaPhone className={iconSize} />
          </div>
          <p className="inline-block ml-2">801-797-5098</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <FaEnvelope className={iconSize} />
          </div>
          <p className="inline-block ml-2">orderyoursushiboba@gmail.com</p>
        </li>
      </ul>
    </div>
  );
};
