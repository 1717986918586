import React from 'react'
import { FaUserFriends } from 'react-icons/fa';
import { GiSoapExperiment, GiSushis } from 'react-icons/gi';

export const Banner = () => {
  const items = [
    { id: 1, icon: <FaUserFriends />, title: 'Friendly Service', desc: "Serve customers with care and friendly" },
    { id: 2, icon: <GiSoapExperiment />, title: 'Cleanliness and Freshness', desc: "Clean and fresh sushi" },
    { id: 3, icon: <GiSushis />, title: 'Customizable Sushi Options', desc: "Customizable sushi requests" }  
  ];
  
  return (
    <div className='flex flex-col py-10 px-6 lg:flex-row lg:space-x-6 bg-white justify-around drop-shadow-md p-4 rounded'>
        {items.map(item => (
            <div className='text-center '>
                <p className='text-orange-600 text-3xl lg:text-5xl w-fit mx-auto pb-4 pt-4 lg:pt-0'>{item.icon}</p>
                <div>
                <h1 className='font-semi-bold front[400] text-xl'>{item.title}</h1>
                <h1 className='font-[200]'>{item.desc}</h1>
                </div>
            </div>
        ))
        }
    </div>
  )
}
