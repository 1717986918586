import React, { useEffect, useState } from "react";
import items from "../Trending/TrendingData";

export const Ingredient = ({ data }) => {
  console.log(data)
  return (
    <div className="text-left">
      <h1 className="font-semibold py-2">Ingredient</h1>
      {data.ingredients && (

        <div className="w-[70%]">
        <p>
          {data.ingredients
            .map(
              (ingredient) =>
                ingredient.charAt(0).toUpperCase() + ingredient.slice(1)
            )
            .join(", ")}
        </p>
      </div>
            )}
      {/* <hr className="my-4" />
      <h1 className="font-semibold py-2">Nutrition Facts</h1>
      <ul>
        {data.nutritionFacts &&
          Object?.entries(data.nutritionFacts).map(([key, value]) => (
            <li key={key}>
              <p>
                <span className="font-[200]">{key}</span>: {value}
              </p>
            </li>
          ))}
      </ul> */}
    </div>
  );
};
