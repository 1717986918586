// src/pages/UnderConstruction.js
import React from 'react';
import { motion } from 'framer-motion';

export const UnderConstruction = () => {
  return (
    <div className="flex items-center justify-center z-[90000] min-h-screen bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url(https://source.unsplash.com/1600x900/?construction)' }}>
      <motion.div 
        initial={{ opacity: 0, scale: 0.5 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 0.8, ease: "easeInOut" }}
        className="bg-black bg-opacity-70 p-8 rounded-lg text-center text-white max-w-lg"
      >
        <motion.h1 
          initial={{ y: -50, opacity: 0 }} 
          animate={{ y: 0, opacity: 1 }} 
          transition={{ duration: 0.6, delay: 0.3 }}
          className="text-4xl md:text-6xl font-bold mb-4"
        >
          Site Under Construction
        </motion.h1>
        <motion.p 
          initial={{ y: 50, opacity: 0 }} 
          animate={{ y: 0, opacity: 1 }} 
          transition={{ duration: 0.6, delay: 0.6 }}
          className="text-lg md:text-xl text-gray-300"
        >
          You are visting Fresh Sushi Boba Tea. We are working hard to bring you an amazing experience. Please check back soon!
        </motion.p>
      </motion.div>
    </div>
  );
};
