import React from "react";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

export const Hours = () => {
  const iconSize = "text-lg"; // Define the size for all icons
  const listItemClass = "flex items-center py-2";

  return (
    <div>
      <h1 className="font-semibold">STORE HOURS</h1>
      <ul className="text-gray-500">
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Sunday</p>
          </div>
          <p className="inline-block ml-2">Closed</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Monday</p>
          </div>
          <p className="inline-block ml-2">11 AM - 7:30 PM</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Tuesday</p>
          </div>
          <p className="inline-block ml-2">11 AM - 7:30 PM</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Wednesday</p>
          </div>
          <p className="inline-block ml-2">11 AM - 7:30 PM</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Thursday</p>
          </div>
          <p className="inline-block ml-2">11 AM - 7:30 PM</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Friday</p>
          </div>
          <p className="inline-block ml-2">11 AM - 7:30 PM</p>
        </li>
        <li className={listItemClass}>
          <div className="inline-block">
            <p>Saturday</p>
          </div>
          <p className="inline-block ml-2">11 AM - 7:30 PM</p>
        </li>
       
      </ul>
    </div>
  );
};
