import React, { useState, useEffect, useRef } from 'react';

export const Offer = () => {
  const imageUrl = 'https://images.pexels.com/photos/1028427/pexels-photo-1028427.jpeg?auto=compress&cs=tinysrgb&w=600';
  const [isInView, setIsInView] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 } // Adjust this threshold value as needed
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <div className="relative -z-10 py-20" ref={imageRef}>
      {isInView && (
        <div
          className="fixed inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
      )}
      <div className="relative z-10 py-20 px-8 max-w-7xl mx-auto">
        <h1 className="text-4xl lg:text-7xl text-orange-600">2024</h1>
        <h2 className="text-white font-bold py-2 text-3xl lg:text-5xl">SUSHI MADE WITH LOVE</h2>
        <p className="text-3xl italic lg:text-3xl text-white">COME AND SEE US</p>
      </div>
    </div>
  );
};
