import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

const GoogleReviews = () => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const loader = new Loader({
            apiKey: 'AIzaSyDFsaRgxc2zJ7aAirYrcIr59rfgLHRdgGs',
            libraries: ['places']
        });

        loader.load().then(() => {
            const placeService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placeService.getDetails({
                placeId: 'ChIJE_CuEgD3UocRG42_6NkMjPI' // Replace with your business's Place ID
            }, (place, status) => {
                if (status === 'OK' && place.reviews) {
                    setReviews(place.reviews);
                }
            });
        });
        
    }, []);

    return (
        <div>
            <h2>Google Reviews</h2>
            {reviews.map((review, index) => (
                <div key={index}>
                    <p>{review.text}</p>
                    <p>Rating: {review.rating}</p>
                    <p>Author: {review.author_name}</p>
                </div>
            ))}
        </div>
    );
};

export default GoogleReviews;