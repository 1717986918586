import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/logobg.png'
import { useNavigate } from "react-router-dom";
import { AppProvider } from "../../App";
export const SignupForm = () => {

  const { setLogOrSign, url, localUrl } = useContext(AppProvider);
  const [err, setErr] = useState('')
  const [success, setSuccess] = useState('')
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${url}api/signup`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
      });

      toast.success(response.data.message); // Display success message from backend
      setFormData({
        username: "",
        email: "",
        password: "",
        rememberMe: false,
      });
    } catch (error) {
      console.log(error.response)
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          // Bad request (validation errors)
          if (error.response) {
            data.errors.forEach((errorMsg) => {
              toast.error(errorMsg);
            });
          } else {
            toast.error(data.message || "Failed to register. Please check your inputs.");
          }
        } else if (status === 404) {
          toast.error(data.message || "User not found.");
        } else if (status === 401) {
          toast.error(data.message || "Invalid credentials.");
        } else {
          toast.error("Failed to register. Please try again later.");
        }
      } else {
        toast.error("Failed to register. Please try again later.");
      }
    }
  };

  const notify = (message) => toast.error(message);
  const notifysucc = (message) => toast.success(message);

  return (
    <div
      className=""
    
    >
       <a href="/">
        
        <img className="w-32 mx-auto" src={logo} alt="" />
        </a>
        <h1 className="text-center text-4xl font-semibold">Create your free account</h1>
        <p className="text-center w-3/4 mx-auto py-4 text-gray-500">
          Create your free account to make orders.
          No credit card required.
        </p>
      <ToastContainer />
      {/* {err && <p className="text-red-500 w-full bg-white z-[50000] text-center text-xl py-2.5 drop-shadow-2xl fixed top-10 left-0 transition-all duration-300 ease-in-out">{err.message}</p>}
      {success && <p className="text-green-500 w-full bg-white z-[50000] text-center text-xl py-2.5 drop-shadow-2xl fixed top-10 left-0 transition-all duration-300 ease-in-out">{success.message}</p>} */}
      <form className="max-w-md w-full mx-auto p-6 bg-white" onSubmit={handleSubmit}>
        <div className="mb-5">
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
            placeholder="Username"
            required
          />
        </div>
        <div className="mb-5">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
            placeholder="Email"
            required
          />
        </div>
        <div className="mb-5">
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
            required
          />
        </div>
        <button
            type="submit"
            className="rounded-2xl text-white bg-orange-500 hover:bg-orange-300 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium  text-sm w-full  px-5 py-2.5 text-center"
          >
            Sign up
          </button>
        <div className="flex items-center justify-between py-4">
          <p>Already have an account?</p>
          <button
            onClick={() => setLogOrSign(true)}
            className="text-gray-500  cursor-pointer mt-3"
            >
            Login
          </button>
            </div>
      </form>

    </div>
  );
};


