import React from "react";

export const Navigation = () => {
  return (
    <div>
      <h1 className="font-bold">Navigation</h1>
      <ul className="py-2 leading-10 text-gray-500">
        <li><a href="/">Home</a></li>
        <li><a href="/menu">Menu</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/contact">Contact</a></li>
        <li><a href="/reviews">Reviews</a></li>
      </ul>
    </div>
  );
};
