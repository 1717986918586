import React, { useContext } from "react";
import {
  RiDashboardLine,
  RiShoppingCartLine,
  RiProductHuntLine,
  RiMailLine,
  RiCloseLine,
} from "react-icons/ri";
import { MdOutlineReviews } from "react-icons/md";
import { MdRestaurantMenu } from "react-icons/md";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { AppProvider } from "../../App";

export const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(AppProvider);

  const handleClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
    // localStorage.setItem("isSidebarOpen", !isSidebarOpen); // Update localStorage
  };

  return (
    <div>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black/60 z-[9000]"
          onClick={handleClick}
        ></div>
      )}
      <div
        id="drawer-navigation"
        className={`fixed top-0 left-0 z-[9000] h-screen p-4 overflow-y-auto transition-transform bg-white w-64 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <h5 className="text-base font-semibold text-gray-500 uppercase">
          Navigation
        </h5>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center"
          onClick={handleClick} // Update to close sidebar
        >
          <RiCloseLine className="w-5 h-5" />
          <span className="sr-only">Close menu</span>
        </button>
        <div className="py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            <li>
              <a
                href="/"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                onClick={handleClick} // Update to close sidebar
              >
                <RiDashboardLine className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-gray-900" />
                <span className="ms-3">Home</span>
              </a>
            </li>
            <li>
              <a
                href="/menu"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                onClick={handleClick} // Update to close sidebar
              >
                <MdRestaurantMenu className="flex-shrink-0 w-5 h-5 text-gray-300 transition duration-75 group-hover:text-gray-900" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Menu
                </span>
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                onClick={handleClick} // Update to close sidebar
              >
                <FaPersonCircleQuestion className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-gray-900" />
                <span className="ms-3">About</span>
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                onClick={handleClick} // Update to close sidebar
              >
                <RiMailLine className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-gray-900" />
                <span className="ms-3">Contact</span>
              </a>
            </li>
            <li>
              <a
                href="/reviews"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                onClick={handleClick} // Update to close sidebar
              >
                <MdOutlineReviews className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-gray-900" />
                <span className="ms-3">Reviews</span>
              </a>
            </li>
            <li className="hover:text-orange-200 bg-orange-500 text-white rounded-xl px-4 py-3"><a href="/admin">LOGIN</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
