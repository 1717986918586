import React, { useState } from "react";
import items from "../components/Trending/TrendingData";
import { TrendingCard } from "../components/Trending/TrendingCard";
import underline from "../assets/underline.png";

export const Menu = () => {
  const [selectedTab, setSelectedTab] = useState("All"); // Set default selected tab to 'All'

  // Filter items based on the selected tab
  const filteredItems =
    selectedTab === "All"
      ? items
      : items.filter((item) => item.category === selectedTab);

  // Array of available categories
  const categories = [
    "All",
    "Sushi",
    "Milk Teas",
    "Slushies",
    "Smoothies",
    "Fruit Teas",
    "Soup",
    "Grill & Stir fry",
    "Deep Fried",
    "Other Drinks",
    "Soft Drinks",
    "Extra",
  ]; // Added more categories for testing

  // Function to handle tab selection and scroll to top
  const handleTabSelect = (category) => {
    setSelectedTab(category);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scrolls to top smoothly
  };

  return (
    <>
      <div className="relative w-fit mx-auto transition-all duration-300 ease-linear">
        <h1 className="title text-4xl text-center py-4">Menu</h1>
        <img
          className="w-full h-20 absolute -bottom-6 left-0"
          src={underline}
          alt="Underline"
        />
      </div>

      {/* Horizontal scrolling for category tabs */}
      <div
        id="tabs"
        className="sticky top-24 z-[9000] bg-white flex hidescroll overflow-x-auto whitespace-nowrap lg:justify-center space-x-4 items-center rounded border border-gray-100 px-4 py-2 w-full mx-auto"
      >
        {/* Render tabs for each category */}
        {categories.map((category) => (
          <div
            key={category}
            className={`px-5 py-2.5 shrink-0 rounded cursor-pointer transition-all duration-300 ease-linear ${
              selectedTab === category ? "bg-orange-200" : "bg-orange-50"
            }`}
            onClick={() => handleTabSelect(category)}
          >
            <p
              className={`${
                selectedTab === category ? "text-orange-500" : "text-orange-500"
              }`}
            >
              {category}
            </p>
          </div>
        ))}
      </div>

      {/* Menu Items Grid */}
      <div className="max-w-8xl mx-auto grid md:grid-cols-3 gap-4 items-center py-10 px-4 lg:px-10 transition-all duration-300 ease-linear">
        {/* Render TrendingCard components for filtered items */}
        {filteredItems.map((item, index) => (
          <TrendingCard key={index} item={item} />
        ))}
      </div>
    </>
  );
};
