import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { toast } from "react-toastify";

const PriceSelector = ({
  sizes,
  flavors,
  base,
  prices,
  toppings,
  toppingsExtra,
  onSelect,
  onClose,
  meats,
  spiceLevels, // Array of spice levels to choose from
  price,
  carb,
  drinks
}) => {
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [selectedFlavor, setSelectedFlavor] = useState("");
  const [selectedBase, setSelectedBase] = useState("");
  const [selectedToppingsExtra, setSelectedToppingsExtra] = useState([]);
  const [selectedDrinks, setSelectedDrinks] = useState([]);
  const [selectedMeats, setSelectedMeats] = useState([]); // For multiple meat selection
  const [selectedSize, setSelectedSize] = useState(null); // Track selected size
  const [selectedSpiceLevel, setSelectedSpiceLevel] = useState(null); // For spice level selection
  const [selectedCarb, setSelectedCarb] = useState(null); // For spice level selection
  const [isExtraToppingsVisible, setIsExtraToppingsVisible] = useState(false);

  // Error state
  const [errors, setErrors] = useState({
    size: false,
    base: false,
    flavor: false,
    meats: false,
    carb: false,
    toppings: false,
    spices: false,
    drinks: false
  });

  // Function to handle meat selection
  const handleMeatChange = (meat) => {
    setSelectedMeats(
      (prevMeats) =>
        prevMeats.includes(meat)
          ? prevMeats.filter((m) => m !== meat) // Uncheck
          : [...prevMeats, meat] // Check
    );
  };

  // Handle the selection and pass data to the parent
  const handleSelect = () => {
    const toppingsTotal = selectedToppingsExtra.reduce((total, topping) => {
      const toppingDetails = toppingsExtra.find((t) => t.name === topping);
      return toppingDetails.price ? total + toppingDetails.price : total;
    }, 0);

    // const drinksTotal = selectedDrinks.reduce((total, drink) => {
    //   const toppingDetails = selectedDrinks.find((t) => t.name === drink);
    //   return toppingDetails.price ? total + toppingDetails.price : total;
    // }, 0);

    // console.log('drinks', drinksTotal)


    const totalPrice =
      (selectedSize ? prices[sizes.indexOf(selectedSize)] : 0) + toppingsTotal;

    // Send the selected size, selected toppings, selected meats, selected spice level, and total price
    onSelect({
      size: selectedSize,
      selectedToppings,
      selectedToppingsExtra,
      selectedMeats,
      selectedSpiceLevel,
      totalPrice,
      selectedCarb,
      selectedBase,
      selectedFlavor,
      selectedDrinks
    });

    console.log("Selected Size:", selectedSize);
    console.log("Selected base:", selectedBase);
    console.log("Selected flavor:", selectedFlavor);
    console.log("Selected Toppings:", selectedToppings);
    console.log("Selected ToppingsExtra:", selectedToppingsExtra);
    console.log("Selected Meats:", selectedMeats);
    console.log("Selected Spice Level:", selectedSpiceLevel);
    console.log("Total Price:", totalPrice);
    console.log("selected carb:", selectedCarb);

    // Close the modal
    onClose();
  };

  const handleToppingChange = (topping) => {
    setSelectedToppings((prev) =>
      prev.includes(topping)
        ? prev.filter((t) => t !== topping)
        : [...prev, topping]
    );
  };

  const handleExtraToppingChange = (topping) => {
    setSelectedToppingsExtra((prev) =>
      prev.includes(topping)
        ? prev.filter((t) => t !== topping)
        : [...prev, topping]
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    //  // Check if at least one meat is selected
     if (meats?.length > 0 && selectedMeats?.length === 0) {
      toast.error('Please select at least one meat option.');
      return;
    }
    //  // Check if at least one meat is selected
     if (drinks?.length > 0 && selectedToppingsExtra?.length === 0) {
      toast.error('Please select at least one drink option.');
      return;
    }
 // Perform validation checks only if those fields exist
 let hasError = false;

 if (sizes && sizes.length > 0 && !selectedSize) {
   setErrors((prev) => ({ ...prev, size: true }));
   toast.error("Please select a size!");
   hasError = true;
 }

 if (base && base.length > 0 && !selectedBase) {
   setErrors((prev) => ({ ...prev, base: true }));
   toast.error("Please select a base!");
   hasError = true;
 }

 if (flavors && flavors.length > 0 && !selectedFlavor) {
   setErrors((prev) => ({ ...prev, flavor: true }));
   toast.error("Please select a flavor!");
   hasError = true;
 }
 if (carb && carb.length > 0 && !selectedCarb) {
   setErrors((prev) => ({ ...prev, carb: true }));
   toast.error("Please select a carb!");
   hasError = true;
 }
 if (meats && meats.length > 0 && !selectedMeats) {
   setErrors((prev) => ({ ...prev, meats: true }));
   toast.error("Please select a meat!");
   hasError = true;
 }
 if (toppings && toppings.length > 0 && !selectedToppings) {
   setErrors((prev) => ({ ...prev, selectedToppings: true }));
   toast.error("Please select a topping!");
   hasError = true;
 }
 if (spiceLevels && spiceLevels.length > 0 && !spiceLevels) {
   setErrors((prev) => ({ ...prev, spiceLevels: true }));
   toast.error("Please select a spice level!");
   hasError = true;
 }
 if (drinks && drinks.length > 0 && !drinks) {
   setErrors((prev) => ({ ...prev, drinks: true }));
   toast.error("Please select a drink!");
   hasError = true;
 }

 if (hasError) {
   return; // Stop form submission if there are errors
 }

 handleSelect(); // Call the function to handle selection
};

  return (
    <form
      className="fixed z-[9000] inset-0 flex items-center justify-center bg-black bg-opacity-50"
      onSubmit={handleSubmit}
    >
      <div className="bg-white p-6 h-[500px] overflow-y-scroll rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-4">Select Options</h3>

        {/* Size selection */}
        <ul>
          {sizes?.map((size, index) => (
            <li key={index} className="mb-2">
              <button
                type="button"
                onClick={() => setSelectedSize(size)} // Select size
                className={`text-lg w-full font-semibold px-4 py-2 rounded-lg border-2 hover:border-none text-black hover:text-white hover:bg-orange-500 ${
                  selectedSize === size
                    ? "bg-orange-500 text-white border-none"
                    : ""
                }`}
              >
                {size} - ${prices[index].toFixed(2)}
              </button>
            </li>
          ))}
        </ul>
        {errors.size && <p className="text-red-500">Please select a size!</p>}
    

        {/* Meat selection */}
        {meats && (
          <div className="z-50 p-4 bg-white shadow-lg rounded-lg mt-4">
            <h1 className="text-2xl text-orange-500">{price}</h1>
            <h3 className="text-lg font-semibold mb-2">Choice of Meat</h3>
            <div className="space-y-2">
              {meats?.map((meat, index) => (
                <label
                  key={index}
                  className="flex items-center space-x-3 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={selectedMeats.includes(meat.name)}
                    onChange={() => handleMeatChange(meat.name)}
                    // required
                    className="form-checkbox h-5 w-5 text-orange-500 border-gray-300 rounded"
                  />
                  <span className="text-gray-800">{meat.name}</span>
                </label>
              ))}
            </div>
          </div>
        )}
        {/* drink selection */}
        {/* {drinks && (
          <div className="z-50 p-4 bg-white shadow-lg rounded-lg mt-4">
            <h1 className="text-2xl text-orange-500">{price}</h1>
            <h3 className="text-lg font-semibold mb-2">Choice of drinks</h3>
            <div className="space-y-2">
              {drinks?.map((drink, index) => (
                <label
                  key={index}
                  className="flex items-center space-x-3 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={selectedDrinks.includes(drink)}
                    onChange={() => setSelectedDrinks(prev => [...prev,drink])}
                    // required
                    className="form-checkbox h-5 w-5 text-orange-500 border-gray-300 rounded"
                  />
                  <span className="text-gray-800">{drink.name}</span>
                  <span className="font-bold">${drink.price}</span>
                </label>
              ))}
            </div>
          </div>
        )} */}

        {carb && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Select Carb</h4>
            <ul className="space-y-2">
              {carb?.map((carb, index) => (
                <li key={index} className="flex items-center mb-2">
                  <input
                    type="radio"
                    id={`carb-${index}`}
                    name="carb"
                    checked={selectedCarb === carb.name}
                    onChange={() => setSelectedCarb(carb.name)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor={`carb-${index}`} className="text-lg">
                    {carb.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Spice Level selection */}
        {spiceLevels && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Select Spice Level</h4>
            <ul className="space-y-2">
              {spiceLevels?.map((spice, index) => (
                <li key={index} className="flex items-center mb-2">
                  <input
                    type="radio"
                    id={`spice-${index}`}
                    name="spice"
                    checked={selectedSpiceLevel === spice.name}
                    onChange={() => setSelectedSpiceLevel(spice.name)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor={`spice-${index}`} className="text-lg">
                    {spice.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Base Selection */}
        {base && base.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Select Base</h4>
            <ul>
              {base?.map((base, index) => (
                <li key={index} className="flex items-center mb-2">
                  <input
                    type="radio"
                    id={`base-${index}`}
                    checked={selectedBase === base.name}
                    onChange={() => setSelectedBase(base.name)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor={`base-${index}`} className="text-lg">
                    {base.name}
                  </label>
                </li>
              ))}
            </ul>
            {errors.base && (
              <p className="text-red-500">Please select a base!</p>
            )}
          </div>
        )}

        {/* Flavor Selection */}
        {flavors && flavors.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Select Flavor</h4>
            <ul>
              {flavors?.map((flavor, index) => (
                <li key={index} className="flex items-center mb-2">
                  <input
                    type="radio"
                    id={`flavor-${index}`}
                    checked={selectedFlavor === flavor.name}
                    onChange={() => setSelectedFlavor(flavor.name)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor={`flavor-${index}`} className="text-lg">
                    {flavor.name}
                  </label>
                </li>
              ))}
            </ul>
            {errors.flavor && (
              <p className="text-red-500">Please select a flavor!</p>
            )}
          </div>
        )}

        {/* {toppings && ( */}
        {/* Topping Selection */}
        <div className={toppings ? "mt-4" : "hidden"}>
          <h4 className="text-lg font-semibold mb-2">
            Select Toppings
          </h4>
          <ul>
            {toppings?.map((topping, index) => (
              <li key={index} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`topping-${index}`}
                  checked={selectedToppings.includes(topping.name)}
                  onChange={() => handleToppingChange(topping.name)}
                  className="mr-2"
                />
                <label htmlFor={`topping-${index}`} className="text-lg">
                  {topping.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
{/* )} */}

        {/* Extra Toppings Accordion */}
        <div className={toppingsExtra ? "mt-4" : "hidden"}>
          <h4
            className="text-lg font-semibold mb-2 flex items-center cursor-pointer"
            onClick={() => setIsExtraToppingsVisible(!isExtraToppingsVisible)}
          >
            {<p>{drinks?.length > 0 ?  "Select a Drink" : "Select Extra Toppings(optional)" }</p>}
            {isExtraToppingsVisible ? (
              <FaChevronUp className="ml-2" />
            ) : (
              <FaChevronDown className="ml-2" />
            )}
          </h4>
          {isExtraToppingsVisible && (
            <ul className="transition-all duration-300 ease-in-out">
              {toppingsExtra?.map((topping, index) => (
                <li key={index} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`extra-topping-${index}`}
                    checked={selectedToppingsExtra.includes(topping.name)}
                    onChange={() => handleExtraToppingChange(topping.name)}
                    className="mr-2"
                  />
                  <label htmlFor={`extra-topping-${index}`} className="text-lg">
                    {topping.name} (+${topping.price?.toFixed(2)})
                  </label>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-4 mt-4">
          <button
            type="button"
            onClick={onClose}
            className="w-full bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 w-full"
          >
            Order
          </button>
        </div>
      </div>
    </form>
  );
};

export default PriceSelector;
