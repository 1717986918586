import React, { useContext, useEffect, useState } from 'react'
import { SignupForm } from './signupForm'
import LoginForm from './LoginForm'
import { useNavigate } from 'react-router-dom'
import { AppProvider } from '../../App'

export const Admin = () => {

const {logOrSign} = useContext(AppProvider)
const [imageIndex, setImageIndex] = useState(0);

// Array of image URLs
const imageUrls = [
  'https://images.pexels.com/photos/539430/pexels-photo-539430.jpeg?auto=compress&cs=tinysrgb&w=800',
  'https://images.pexels.com/photos/2098134/pexels-photo-2098134.jpeg?auto=compress&cs=tinysrgb&w=800',
  'https://images.pexels.com/photos/1199982/pexels-photo-1199982.jpeg?auto=compress&cs=tinysrgb&w=800',
  'https://images.pexels.com/photos/6413609/pexels-photo-6413609.jpeg?auto=compress&cs=tinysrgb&w=800'
];

// Function to change the image index
const changeImage = () => {
  setImageIndex(prevIndex => (prevIndex + 1) % imageUrls.length);
};

// Effect to change image every 60 seconds
useEffect(() => {
   
  const interval = setInterval(changeImage, 6000);

  // Clean up interval on component unmount
  return () => clearInterval(interval);
}, []); // Empty dependency array ensures this effect runs only once on mount


  return (
    <div className='flex flex-col lg:flex-row items-center justify-between'>
      <div className='w-full lg:w-1/2 h-screen flex items-center justify-center'>

      {logOrSign ? 
      <LoginForm/>
      :
      <SignupForm/>
    }
    </div>
      <img
        src={imageUrls[imageIndex]}
        className='hidden lg:flex  w-full lg:w-1/2 h-screen object-cover transition-all duration-300 ease-linear'
        alt=""
      />

    </div>
  )
}
