// components/NotFound.js
import React from 'react';
import notfound from '../assets/404.png'
const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img className='w-full h-80 object-contain animate-bounce' src={notfound} alt="" />
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-lg">Oops! The page you are looking for does not exist.</p>
      {/* You can add more content or links here as needed */}
    </div>
  );
};

export default NotFound;
