import React from 'react'
import { NewProductCard } from './NewProductCard'
import items from '../Trending/TrendingData'
import { CiHeart } from 'react-icons/ci'
import { FaStar } from 'react-icons/fa'
import underline from '../../assets/underline.png'
import noodlesoup from '../../assets/noodlesoup.png'
export const NewProduct = () => {
  return (
    <div className='bg-white py-20'>
    <div className=' px-8 max-w-7xl mx-auto flex flex-col lg:flex-row lg:space-x-7'>
        <div className='border w-full'>
        <h1 className='border-b sticky bg-white z-[4000] top-0 w-full p-4'>NEW PRODUCT</h1>
        {items.slice(0, 3).map(item => (
        <NewProductCard key={item.id} item={item} />
      ))}
        </div>
        <div>

        <div className='relative w-fit mx-auto'>
    <h1 className='title text-4xl text-center py-4'>SPECIAL PRODUCT</h1>
    <img className='w-full h-20 absolute -bottom-6 left-0' src={underline} alt="" />
    </div>
        <div className="lg:w-80 group  rounded-lg drop-shadow-md text-center py-4">
      <img className="w-full h-60 object-contain" src={noodlesoup} alt="" />
      {/* <button className="bg-gray-200 px-4 py-2 w-full">
        Add to Cart
      </button> */}
      <div className="flex items-center space-x-1 justify-center pt-4">
      {Array(5).fill(<FaStar className='text-yellow-500'/>)}
      </div>
      <p className="font-[300]">Thai beef noodle soup</p>
      <div className="flex items-center space-x-2 justify-center">
        <p className="font-bold text-xl">12.99</p>
      
      </div>
      <CiHeart className="absolute cursor-pointer bg-gray-200 p-2 block group-hover:block lg:hidden top-0 right-0 rounded-lg text-4xl"/>
    </div>
        </div>
        <div className='border w-full '>
        <h1 className='border-b sticky bg-white z-[4000] top-0 w-full p-4'>NEW PRODUCT</h1>
        {items.slice(-3).map(item => (
        <NewProductCard key={item.id} item={item} />
      ))}
        </div>
    </div>
    </div>
  )
}
