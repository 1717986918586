import React from 'react'
import { NewsLetter } from './NewsLetter'
import { Left } from './Left'
import { Account } from './Account'
import { Navigation } from './Navigation'
import { Info } from './Info'
import { Hours } from './Hours'

export const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className='bg-white pb-6'>
       <NewsLetter/>
       <hr className='w-[90%] h-[1px] my-8 bg-black mx-auto' />
       <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto px-7 '>
       <Left/>
       {/* <Account/> */}
       <div></div>
       <Navigation/>
       <Info/>
       <Hours/>
       </div>
       <p className='text-center px-4 py-4 text-gray-300 text-xs'>&copy; {getCurrentYear()} Fresh Sushi & Boba Tea Co | All Rights Reserved. Website done by <a className='text-orange-500' target='_blank' href='https://www.winagencydesigns.com/'>winagencydesigns</a></p>
    </div>
  )
}
