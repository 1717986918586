import React from 'react'
import spinner from '../../assets/Spinner-5.gif'
export const Spinner = () => {
  return (
    <div className='fixed inset-0 bg-white z-[15000] h-screen w-full flex flex-col items-center justify-center'>
        <div className='flex items-center justify-between space-x-4'>
            <div className='w-20 h-20 rounded-full bg-gray-100 animate-pulse'></div>
            <div className='flex items-center space-x-4'>
                <div className='w-40 h-10 bg-gray-100 animate-pulse'></div>
                <div className='w-40 h-10 bg-gray-100 animate-pulse'></div>
                <div className='w-40 h-10 bg-gray-100 animate-pulse'></div>
                <div className='w-40 h-10 bg-gray-100 animate-pulse'></div>
            </div>
            <div className='w-40 h-10 bg-gray-100 animate-pulse'></div>
        </div>
        <div className='bg-gray-100 animate-pulse my-8 w-full h-80 '></div>
        <div className='flex items-center space-x-4 '>
            <div className='w-80 h-40 rounded bg-gray-100 animate-pulse'></div>
            <div className='w-80 h-40 rounded bg-gray-100 animate-pulse'></div>
            <div className='w-80 h-40 rounded bg-gray-100 animate-pulse'></div>
            <div className='w-80 h-40 rounded bg-gray-100 animate-pulse'></div>
        </div>
    </div>
  )
}
