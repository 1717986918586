import React from "react";
import { Form } from "../components/Contact/Form";

export const Contact = () => {
  const styles = {
    backgroundImage: `url('https://images.pexels.com/photos/1416530/pexels-photo-1416530.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "400px", // Adjust height as needed
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // Add this to make the overlay absolute positioned
  };
  return (
    <div className="py-6">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <div>
            <iframe
              className="rounded"
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96630.27355286652!2d-111.90041333694734!3d40.860432493123064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752813f2b2c5697%3A0x4d9db9b7dfbbd104!2s3391%20Orchard%20Dr%2C%20Bountiful%2C%20UT%2084010%2C%20USA!5e0!3m2!1sen!2sbd!4v1649344882239!5m2!1sen!2sbd"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
        <Form />
      </div>
    </div>
  );
};
