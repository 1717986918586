import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
import { Loader } from "@googlemaps/js-api-loader";
export const Slider = ({ product }) => {
  const [showModal, setShowModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyDFsaRgxc2zJ7aAirYrcIr59rfgLHRdgGs",
      libraries: ["places"],
    });

    loader.load().then(() => {
      const placeService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      placeService.getDetails(
        {
          placeId: "ChIJE_CuEgD3UocRG42_6NkMjPI", // Replace with your business's Place ID
        },
        (place, status) => {
          if (status === "OK" && place.reviews) {
            setReviews(place.reviews);
          }
        }
      );
    });
  }, []);

  const openModal = (src) => {
    setVideoSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setVideoSrc("");
  };

  const [products, setProducts] = useState([
    {
      id: 0,
      name: "Bruno Ortiz",
      company: "Store Director, Hill AFB",
      // image: 'https://images.pexels.com/photos/18277222/pexels-photo-18277222/free-photo-of-cinematic-view.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
      describe:
        "Mr. Sanuk's sushi spot is a local favorite. With fresh sushi daily, quick service, and top-notch customer care from him and his wife, it's no wonder they're loved. Their consistent quality and community support shine through effortlessly.",
    },
    {
      id: 1,
      name: "Scott Holm",
      company: "Store Director, Smith's Market Place",
      image:
        "https://images.pexels.com/photos/8955380/pexels-photo-8955380.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
      describe:
        "Sanuk has transformed our sushi bar since joining our department, tripling our weekly sales. Regulars flock to our store, drawn by its cleanliness and the exceptional rapport Sanuk fosters with his team. He's a rule-follower, never stirring trouble, and exudes friendliness. I wish I could stay with them and would recommend their sushi operation to run any store.",
    },
    {
      id: 2,
      name: "John",
      company: "Employee of the store, Hill AFB",
      // image: 'https://images.pexels.com/photos/18608193/pexels-photo-18608193/free-photo-of-teide-nationalpark-teneriffa.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
      describe:
        "Sanuk's sushi is a crowd favorite, with numerous customers praising it as the best in town. Many are eager to visit and try it out for themselves. Sanuk's customer-oriented approach shines through, as he's often asked where he sources his sushi. His friendly demeanor and dedication to customer satisfaction make his sushi bar a must-visit destination.",
    },
  ]);

  function truncate(myString, length){
    return myString.substring(0,length) + '...'
  }
  return (
    <>
      <Splide
        options={{
          rewind: true,
          gap: "1rem",
          autoplay: true,
          arrows: false,
        }}
        aria-label="My Favorite Images"
      >
        {reviews.map((review) => (
          <SplideSlide>
            <div className="text-gray-500">
              <div className="">
                <p className="flex">
                  <span className="text-xl font-bold pr-2 text-orange-600">
                    <ImQuotesLeft />
                  </span>
                  <p className="">{truncate(review.text, 150)}</p>
                  <span className="text-xl font-bold pl-2 text-orange-600">
                    <ImQuotesRight />
                  </span>
                </p>
              </div>
              <div className="flex items-center space-x-4 my-8">
                {!review.profile_photo_url ? (
                  <p className="w-20 h-20 rounded-full border flex text-4xl font-bold items-center justify-center ">
                    {review.author_name}
                  </p>
                ) : (
                  <img
                    className="w-20 h-20 object-cover rounded-full"
                    src={review.profile_photo_url}
                    alt="Image1"
                  />
                )}
                <div>
                  <h1 className="font-bold">{review.author_name}</h1>
                  {/* <p className="text-gray-500 font-[200]">{review.company}</p> */}
                  <button onClick={() => openModal(product.videoSrc)}  id="see_view" className="border px-5 py-2.5 rounded my-2 bg-orange-500 text-white">
                    See video reviews
                  </button>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
      {showModal && (
        <div className="fixed inset-0 h-screen w-full z-[12000] flex items-center justify-center">
          <div
            id="video_modal"
            className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center"
            onClick={closeModal} // Close modal when clicked outside video area
          >
            <video
              width="750"
              height="500"
              controls
              className="z-[13000]"
              src={videoSrc} // Set video source
            />
          </div>
        </div>
      )}
    </>
  );
};
