import React from 'react'
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { AiFillLinkedin, AiOutlineGithub } from 'react-icons/ai';
import Logo from '../../assets/2020logos.png'
export const Left = () => {
    const iconSize = 20; // Size in pixels

  return (
    <div>
        <img className='w-24 h-10 object-contain' src={Logo} alt="" />
        <p className='py-4 text-gray-500'>We're family here, ensuring each moment is smooth and delightful with our carefully crafted flavors.</p>
        <div className='flex items-center space-x-6'>
      <FaFacebook size={iconSize} />
      <FaTwitter size={iconSize} />
      <FaInstagram size={iconSize} />
      <AiFillLinkedin size={iconSize} />
      {/* <AiOutlineGithub size={iconSize} /> */}
    </div>
    </div>
  )
}
