import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import logo from '../../assets/logobg.png'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { AppProvider } from "../../App";
const LoginForm = () => {
  const { userLogged, setLogOrSign, localUrl, url } = useContext(AppProvider);

  const [currentUser, setCurrentUser] = useState("");
  const [success, setSuccess] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${url}api/login`, {
        email: formData.email,
        password: formData.password,
      });
      setCurrentUser(response.data.res);
      console.log(response.data);
      toast.success(response.data.message); // Display success message from backend
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("logged", response.data.res);
      navigate('/')


      // Reset form fields
      setFormData({
        email: "",
        password: "",
        rememberMe: false,
      });

      // Redirect or handle login success (e.g., store token in local storage)
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          // Bad request (validation errors)
          if (data.errors) {
            data.errors.forEach((errorMsg) => {
              toast.error(errorMsg);
            });
          } else {
            toast.error(
              data.message || "Failed to register. Please check your inputs."
            );
          }
        } else if (status === 404) {
          toast.error(data.message || "User not found.");
        } else if (status === 401) {
          toast.error(data.message || "Invalid credentials.");
        } else {
          toast.error("Failed to register. Please try again later.");
        }
      } else {
        toast.error("Failed to register. Please try again later.");
      }
    }
  };

  return (
    <div className="w-full px-6">
      <ToastContainer />
      <a href="/">
        
      <img className="w-32 mx-auto" src={logo} alt="" />
      </a>
      <h1 className="text-center text-4xl font-semibold pb-4">Welcome back</h1>
        <form className="max-w-md w-full mx-auto" onSubmit={handleSubmit}>
          {err && (
            <p className="text-red-500 w-full z-[5000] bg-white  text-center text-xl py-2.5 drop-shadow-2xl fixed top-10 left-0 transition-all duration-300 ease-in-out">
              {err.message}
            </p>
          )}
          <div className="mb-5">
            
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-gray-200 border border-gray-300 text-gray-800 text-sm rounded-2xl focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-5">
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              className="bg-gray-200 border border-gray-300 text-gray-800 text-sm rounded-2xl focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
              required
            />
          <a className="text-xs float-right py-2" href="/request-reset-password">
            forgot password?
          </a>
          </div>
         
          <button
            type="submit"
            className="rounded-2xl text-white bg-orange-500 hover:bg-orange-300 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium  text-sm w-full  px-5 py-2.5 text-center"
          >
            Login
          </button>
          <div className="flex items-center justify-between py-4">
          <p>Don't have an account? </p>
          <button
            onClick={() => setLogOrSign(false)}
            className="text-gray-500  cursor-pointer mt-3"
            >
            Signup
          </button>
            </div>
        
        </form>
    
    </div>
  );
};

export default LoginForm;
