import React, { useContext, useEffect, useState } from "react";
import { RiMenu2Line } from "react-icons/ri";
import { RxAvatar } from "react-icons/rx";
import { AppProvider } from "../App";
import logo from "../assets/2020logos.png";
import { Sidebar } from "./SideBar/Sidebar";
import { IoBagOutline } from "react-icons/io5";
import { IoExitOutline } from "react-icons/io5";
import axios from "axios";
import { FaExclamation } from "react-icons/fa";

export const Navbar = () => {
  const { cart, url, cartChanged, increaseItem, decreaseItem, deletItem } =
    useContext(AppProvider);
  const [scrolled, setScrolled] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(AppProvider);
  const [isAdmin, setIsAdmin] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const loggedUser = localStorage.getItem("logged");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      if (!loggedUser) return; // Do not fetch if user is not logged in

      try {
        const response = await axios.get(`${url}api/cart/${loggedUser}`);
        if (Array.isArray(response.data)) {
          setCartItems(response.data);
        } else {
          setCartItems([]); // Ensure cartItems is an array
        }
        console.log("Fetched cart items:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setCartItems([]); // Ensure cartItems is an array even if there's an error
      }
    };

    const checkUserRole = async () => {
      try {
        const response = await axios.get(`${url}api/one-user/${loggedUser}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { role } = response.data;

        setIsAdmin(role === "admin");
      } catch (error) {
        console.error("Error fetching user role:", error);
        // Default to non-admin role if user role cannot be determined
        setIsAdmin(false);
      }
    };

    checkUserRole();

    fetchData();
  }, [cartChanged, increaseItem, decreaseItem, deletItem]); // Add cartChanged as a dependency

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="z-[8000] sticky top-0 left-0 w-full bg-white drop-shadow-sm">
      {/* <div className="shrink-0 flex items-center space-x-4 justify-center text-sm text-center py-4 px-4 bg-orange-500">
        <FaExclamation className="shrink-0 bg-white p-2 text-2xl rounded-xl"/>
      <p className=" text-white">Our menu and prices etc. are not updated at the moment. We are working on it. Please be patient while we get these sorted out for you. Thanks!</p>
      </div> */}

      <nav className="relative p-4 w-full px-6 h-24 flex justify-between items-center max-w-7xl mx-auto transition-all duration-300 ease-linear">
        <a href="/" className="font-bold text-5xl">
          <img
            className={"w-32 h-full transition-all duration-300 ease-linear"}
            src={logo}
            alt=""
          />
        </a>
        <ul className="hidden text-gray-500 lg:flex items-center justify-center space-x-6 text-xl py-4 mx-6">
          <li className="hover:text-orange-500">
            <a href="/">HOME</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/menu">MENU</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/about">ABOUT</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/contact">CONTACT</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/reviews">REVIEWS</a>
          </li>
        </ul>
        <ul className="mobile hidden bg-white h-screen leading-10 w-60 lg:hidden text-gray-500 items-center justify-center text-xl border-t py-4 mx-6">
          <li>
            <a href="/">HOME</a>
          </li>
          <li>
            <a href="/menu">MENU</a>
          </li>
          <li>
            <a href="/about">ABOUT</a>
          </li>
          <li>
            <a href="/contact">CONTACT</a>
          </li>
          <li>
            <a href="/reviews">REVIEWS</a>
          </li>
        </ul>
        <div className="flex items-center space-x-4 text-3xl">
          {localStorage.getItem("logged") ? (
            <div className="relative">
              <RxAvatar
                className="cursor-pointer"
                onClick={() => setShowLogOut(!showLogOut)}
              />
              {showLogOut && (
                <div className="flex flex-col gap-4 items-center space-x-2 absolute top-14 left-0 text-lg bg-white rounded p-2">
                  <a  className="hover:text-orange-500 flex items-center space-x-2" href="/" onClick={() => localStorage.removeItem("logged")}>
                    <IoExitOutline />
                    <p>Logout</p>
                  </a>

                  {isAdmin && <a className="hover:text-orange-500" href="/owner-view">ownerview</a>}
                </div>
              )}
            </div>
          ) : (
            <a
              className="border shrink-0 px-4 py-3 rounded  hover:text-orange-500 text-lg"
              href="/admin"
            >
              LOGIN
            </a>
          )}
          <a
            href="/contact"
            className="hidden md:block text-xl shrink-0 bg-orange-500 rounded text-white px-4 py-3"
          >
            Contact Us
          </a>
          <div className="relative">
            <a href="/cart" className="cursor-pointer">
              <IoBagOutline />
              <div className="absolute top-0 -right-2 bg-orange-500 text-white h-5 w-5 flex items-center justify-center p-3 font-bold text-sm rounded-full">
                {cartItems.length}
              </div>
            </a>
          </div>
          <RiMenu2Line
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
              console.log(isSidebarOpen);
            }}
            className="lg:hidden text-2xl cursor-pointer"
          />
        </div>
      </nav>
      <Sidebar />
    </div>
  );
};
