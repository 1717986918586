import React from "react";
import { TrendingCard } from "./TrendingCard";
import { FaStar } from "react-icons/fa";
import items from "./TrendingData";
import underline from "../../assets/underline.png";
export const Trending = () => {
  return (
    <div className=" bg-white">
      <div className="pt-[370px] lg:pt-24 px-8 max-w-7xl mx-auto">
        <div className="relative w-fit mx-auto">
          <h2 className="title text-center text-4xl">TRENDING PRODUCTS</h2>
          {/* <h1 className='text-4xl text-center py-4'>Menu</h1> */}
          <img
            className="w-full h-20 absolute -bottom-10 left-0"
            src={underline}
            alt=""
          />
        </div>
        <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-6 items-center space-x-4 py-10">
          {items
            .filter((l) => l.id < 7)
            .map((item) => (
              <TrendingCard item={item} />
            ))}
        </div>
      </div>
      <div className="w-fit mx-auto">

      <a href="/menu" className="bg-orange-500 text-white px-5 py-3 rounded-lg">View all menu</a>
      </div>
    </div>
  );
};
