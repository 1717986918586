import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Cancel = () => {
  const [priceFeedback, setPriceFeedback] = useState("");
  const [checkoutFeedback, setCheckoutFeedback] = useState("");
  const [question, setQuestion] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Trigger cancel alert endpoint
    const triggerCancelAlert = async () => {
      try {
        await axios.get("https://sushiboba.onrender.com/cancel");
      } catch (error) {
        console.error("Error triggering cancel alert:", error);
      }
    };

    triggerCancelAlert();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://sushiboba.onrender.com/api/feedback", {
        priceFeedback,
        checkoutFeedback,
        question,
      });
      setSubmitted(true);
       // Redirect after a short delay
       setTimeout(() => {
        navigate("/"); // Redirect to the 'Thank You' page or any other route
      }, 2000);
    } catch (error) {
      console.error("Error submitting survey:", error);
    }
  };

  return (
    <div className="max-w-lg mx-auto my-10 p-6 rounded shadow-md bg-white">
      <h1 className="text-center text-2xl font-semibold mb-6 text-gray-800">
        You have cancelled your order.
      </h1>

      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <h2 className="text-lg font-medium mb-4 text-gray-700">
            We'd love to hear your feedback:
          </h2>

          {/* Price feedback */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-600">
              How did you find the price?
            </label>
            <select
              value={priceFeedback}
              onChange={(e) => setPriceFeedback(e.target.value)}
              className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            >
              <option value="">Select an option</option>
              <option value="too high">Too high</option>
              <option value="just right">Just right</option>
            </select>
          </div>

          {/* Checkout feedback */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-600">
              How did you feel about the checkout process?
            </label>
            <select
              value={checkoutFeedback}
              onChange={(e) => setCheckoutFeedback(e.target.value)}
              className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            >
              <option value="">Select an option</option>
              <option value="safe">I felt safe</option>
              <option value="unsafe">I felt unsure</option>
            </select>
            {checkoutFeedback === "unsafe" && (
              <div>
                <p className="text-sm text-orange-600 mt-2">
                  Our checkout is powered by Stripe, a secure and trusted
                  payment platform.
                </p>

                <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto mt-10">
                  <h2 className="text-2xl font-bold text-center text-orange-500 mb-6">
                    Why You Should Trust Stripe
                  </h2>
                  <div className="space-y-4">
                    <p className="text-lg">
                      <span className="font-semibold text-orange-500">
                        1. Advanced Security Protocols:
                      </span>{" "}
                      Stripe is certified to PCI Service Provider Level 1, the
                      most stringent level of certification in the payments
                      industry. They use advanced encryption and security tools
                      such as HTTPS and TLS to protect all transaction data.
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold text-orange-500">
                        2. Fraud Detection:
                      </span>{" "}
                      Stripe employs sophisticated fraud detection tools that
                      analyze patterns and data points to prevent unauthorized
                      transactions. Their "Radar" system uses machine learning
                      to catch potential fraud before it reaches the merchant.
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold text-orange-500">
                        3. Global Trust:
                      </span>{" "}
                      Stripe powers payments for major companies worldwide,
                      including Amazon, Shopify, Lyft, and Zoom. This broad
                      adoption and their reputation with large, trusted brands
                      demonstrate their reliability and safety.
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold text-orange-500">
                        4. Compliance with International Standards:
                      </span>{" "}
                      Stripe complies with global security and data protection
                      standards, such as the GDPR in Europe and PSD2 (which
                      requires two-factor authentication in the EU).
                    </p>
                    <p className="text-lg">
                      <span className="font-semibold text-orange-500">
                        5. User Control Over Payment Data:
                      </span>{" "}
                      Stripe never shares full payment details with the merchant
                      or any third parties. Card information is tokenized,
                      ensuring it’s kept confidential, adding an additional
                      layer of privacy for customers.
                    </p>
                    <p className="text-lg text-center font-semibold">
                      Stripe's commitment to security and transparency gives
                      both businesses and customers peace of mind during
                      transactions. It’s one of the safest choices for online
                      payments.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Additional question */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-600">
              Do you have any other questions or feedback?
            </label>
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-orange-500"
              rows="4"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-orange-500 text-white font-semibold p-3 rounded hover:bg-orange-600 transition duration-200"
          >
            Submit Feedback
          </button>
        </form>
      ) : (
        <div className="text-center mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Thank you for your feedback!
          </h2>
          <p className="text-gray-600 mt-2">
            We appreciate your input and will use it to improve our service.
          </p>
        </div>
      )}
    </div>
  );
};
